import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "statelistwrapper"
    }}>{`StateListWrapper`}</h1>
    <p>{`This is generic wrapper around list data that is used to normalize the
handling of situations such as loading, errors, and empty state.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <p>{`Props:`}</p>
    <pre><code parentName="pre" {...{}}>{`* data
* error
* loading
* children
* errorTitle
* errorMessage
* errorActions
* loadingTitle
* loadingMessage
* emptyTitle
* emptyMessage
* emptyActions
* components
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{}}>{`import { StateListWrapper } from '@kineticdata/bundle-common';
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      